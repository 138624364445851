.container {
  padding-bottom: 3em;
}

.dek {
  max-width: 700px;
}

.gridContainer {
  margin: 2em auto 0;
  width: 100%;
  max-width: 900px;
  aspect-ratio: 5/3;
  display: flex;
  justify-content: center;
}

.grid {
  display: grid;
  width: 100%;
  grid-gap: 6px;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(5, 1fr);
}

.gridItem {
  background: linear-gradient(
    180deg,
    var(--background-gradient-top),
    var(--background-gradient-bottom)
  );
  border-radius: 12px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: margin 0.2s ease-out;
  overflow: hidden;
  margin: 4px;
}

.gridItem:hover {
  margin: 0px;
}

.skillText {
  position: absolute;
  bottom: -20px;
  transition: bottom 0.2s ease-out;
  font-weight: 500;
  font-size: 16px;
}

.gridItem:hover > .skillText {
  bottom: 2px;
}

.techImage {
  width: 50%;
  height: 50%;
  object-fit: scale-down;
  transition: width 0.2s ease-out, height 0.2s ease-out;
}

@media screen and (max-width: 680px) {
  .gridContainer {
    width: 100%;
    aspect-ratio: 5/7;
  }

  .grid {
    display: grid;
    width: 100%;
    grid-gap: 2px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(7, 1fr);
  }

  .gridItem {
    margin: 4px;
  }

  .skillText {
    font-size: 14px;
  }
}

@media screen and (max-width: 360px) {
  .skillText {
    font-size: 10px;
  }
}
