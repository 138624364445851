.resumeGrid {
  display: grid;
  width: 100%;
  grid-template-columns: 40px 1fr 6fr;
}

.timeline {
  width: 6px;
  background-color: rgb(30, 144, 255);
  margin: 0 auto 0 12x;
  position: relative;
}

.timeline.end {
  background: linear-gradient(
    180deg,
    rgb(30, 144, 255) 65%,
    rgb(30, 144, 255, 0) 90%
  );
}

.timelineDot {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 5px solid var(--primary-blue);
  background-color: var(--background-primary);
  position: absolute;
  left: -10px;
}

.dateAndLocation {
  margin-right: 1em;
  margin-bottom: 2em;
}

.roleAndDescription {
  margin-bottom: 1em;
}

.noBreak {
  white-space: nowrap;
}

.techList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
  margin-left: 24px;
  margin-bottom: 1em;
}

.location {
  color: var(--content-secondary);
}

@media screen and (max-width: 680px) {
  .resumeGrid {
    grid-template-columns: 40px 1fr;
  }
}
