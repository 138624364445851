.container {
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  position: absolute;
  top: -5px;
  left: -5px;
}

.container > svg path {
  stroke-opacity: 1;
  stroke: white;
  stroke-width: 2px;
  transition: stroke-opacity 0.3s ease-in-out;
}

.mainPath {
  stroke-opacity: 1 !important;
  stroke-width: 4px !important;
}

.maskRect {
  fill: black;
  opacity: 0;
  transform: scale(0.1);
  transform-origin: center;
  transition: fill 0.3s ease-in-out, opacity 0.3s ease-in-out,
    transform 0.3s ease-in;
}

.active .maskRect {
  opacity: 0.2;
  transform: scale(1);
  fill: white;
  transition: fill 0.2s ease-in-out, opacity 0.2s ease-in-out,
    transform 0.5s ease-out;
}
