* {
  transition: color 0.2s;
  transition: background-color 0.2s;
}

/* for SVGs */
.sharedIcon path {
  stroke: var(--content-primary);
}

.page {
  padding: 0 0 80px;
  line-height: 1.4;
}

/* .page-content {
  max-width: 1200px;
  margin: 0 auto;
} */

.top-level-container {
  margin: 3em auto 0;
  padding: 0 1em;
  max-width: 1200px;
}

.vertical-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}

.narrow-vertical-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  max-width: 820px;
  margin: 0 auto;
}

.button {
  display: inline-block;
  height: 40px;
  width: 150px;
  border-radius: 20px;
  border: 1px solid #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
  cursor: pointer;
}

.button.secondary {
  background-color: #ddd;
  border: none;
}

.button.secondary:hover {
  background-color: #ccc;
}

.button-text {
  color: black;
  text-decoration: none;
}

.button-text.secondary {
  color: black;
}

.spacer-xs {
  height: 2em;
}

.spacer-sm {
  height: 4em;
}

.spacer-md {
  height: 6em;
}

.spacer-lg {
  height: 9em;
}
