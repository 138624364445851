.footer {
  margin-top: 100px;
  border-top: var(--border-light);
  padding: 1em 1em;
  margin: 100px auto 0;
}

.name {
  text-align: center;
  color: var(--content-secondary);
}
