.sidebarBlock {
  min-width: 84px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.centerBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.linksContainer {
  display: flex;
  gap: 1em;
  justify-content: space-between;
  margin-top: 1.5em;
}

.linkColumn {
  display: flex;
  flex-direction: column;
  gap: 1em;
  flex-basis: 33%;
}

.linkColumn ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.linkColumn li {
  font-size: 1rem;
  color: var(--text-color);
}

@media (max-width: 680px) {
  .linksContainer {
    flex-direction: column;
    gap: 3em;
  }
}
