/* nav bar */
.navBarContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 0 1em;
  z-index: 10;
  background-color: var(--background-primary);
  border-bottom: var(--border-light);
}
