.container {
  width: 100%;
  display: flex;
  gap: 32px;
}

.reversed {
  flex-direction: row-reverse;
}

.aboutBlock {
  position: relative;
  background: linear-gradient(180deg, #24a0ff, #0185ff);
  border-radius: 12px;
  width: 60%;
  aspect-ratio: 3/2;
  overflow: clip;
}

.aboutText {
  flex: 0 1 330px;
}

.aboutText :global(.blue) {
  transition: color 0.3s ease-in-out;
}

.active :global(.blue) {
  color: var(--secondary-blue);
}

@media (max-width: 680px) {
  .container {
    display: block;
  }

  .aboutText {
    margin-top: 24px;
  }

  .aboutBlock {
    width: 100%;
    aspect-ratio: 3/2;
    flex: auto;
  }
}
