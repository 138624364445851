.container {
  position: relative;
  width: 100%;
  aspect-ratio: 3/2;
  overflow: hidden;
  background: linear-gradient(180deg, #24a0ff, #0185ff);
  border-radius: 12px;
}

.svgContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.sphere1,
.sphere2 {
  transition: all 0.3s ease-in-out;
  fill: white;
  animation: scale 6s infinite ease-in-out;
}

.active .sphere1 {
  opacity: 0;
}
.active .sphere2 {
  fill: #bc002d;
}

.sphere1,
.sphere2,
.maskSphere {
  animation: scale 6s infinite ease-in-out;
}

.sphere1,
.maskSphere {
  animation-delay: -1.5s;
}

.sphere2 {
  animation-delay: -4.5s;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}
