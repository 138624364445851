.sidebarBlock {
  min-width: 84px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.centerBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.linksContainer {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: fit-content;
}

.emoji {
  display: inline-block;
  font-size: 44px;
  margin: 0 3px;
  transition: all 0.3s ease;
  height: 84px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-content: middle;
  cursor: "pointer" !important;
}

.emoji.hovered {
  font-size: 64px;
}

.emojiLabel {
  font-weight: 600;
  color: var(--primary-blue);
  cursor: "pointer";
  transition: color 0.3s;
}

.emojiLabel.hovered {
  color: var(--secondary-blue);
}
