.projectsSection {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 2em;
  margin: 1em 0;
}

.projectCard {
  border-top: 1px solid var(--content-tertiary);
  padding: 2em 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 7em;
}

.subHed {
  color: var(--content-secondary);
  font-size: 16px;
}

.projectSummary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1em;
  width: 100%;
}

.imageContainer {
  min-height: 250px;
  min-width: 400px;
  width: 33%;
  display: flex;
  justify-content: center;
}

.projectImage {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  display: block;
  margin: auto;
  border-radius: 12px;
}

.bottomContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 4px;
  row-gap: 10px;
}

.techList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
}

.projectLinks {
  display: flex;
  justify-content: flex-end;
  justify-self: end;
  flex-grow: 1;
  flex-shrink: 0;
  gap: 1em;
}

@media (max-width: 960px) {
  .projectCard {
    flex-direction: column-reverse;
    align-items: center;
    gap: 1em;
  }
  .imageContainer {
    min-width: 0;
    min-height: 0;
    width: 100%;
  }
}
