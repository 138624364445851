.textLink {
  text-decoration: none;
  cursor: pointer;
}

.textLinkContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  background: linear-gradient(
      to left,
      var(--content-primary) 0%,
      var(--content-primary) 34%,
      var(--background-primary) 34%,
      var(--background-primary) 66%,
      var(--content-primary) 66%,
      var(--content-primary) 100%
    )
    left bottom rgba(0, 0, 0, 0) no-repeat;
  background-size: 300% 2px;
  background-position-x: 100%;
  transition: background-position-x 0s;
}

.textLinkContent.active {
  background-position-x: 0;
  transition: background-position-x 0.7s;
}

.iconContainer {
  position: absolute;
  width: 20px;
  height: 20px;
  transition: all 0.3s linear;
}

.textLinkContent > div > .topRight {
  left: 0;
  bottom: 0;
}
.textLinkContent:hover > div > .topRight {
  left: 2px;
  bottom: 2px;
}

.textLinkContent > div > .bottom {
  top: 0;
}
.textLinkContent:hover > div > .bottom {
  top: 2px;
}

.textLinkContent > div > .right {
  left: 0;
}
.textLinkContent:hover > div > .right {
  left: 2px;
}
