.linksContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 12px;
}

.mainContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.2s ease;
}

.mainContent.tall {
  height: 50px;
}

.mainContent.short {
  height: 30px;
}

.topContent,
.bottomContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease;
  overflow: hidden;
}

.topContent {
  gap: 8px;
}

.topContent.short,
.bottomContent.short {
  height: 22px;
}

.topContent.tall,
.bottomContent.tall {
  height: 0;
}

.navLink {
  color: var(--content-primary);
  font-size: 20px;
  font-weight: 500;
}

.divider {
  border-right: var(--border-light);
  margin: 6px 8px;
}

.bottomContent {
  opacity: 0;
  transition: 0.3s ease;
}

.bottomContent.showing {
  opacity: 1;
}
