.tagContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  border-radius: 28px;
  border-width: 3px;
  border-style: solid;
  border-color: #8b8b8b;
  padding: 1px 10px;
  font-weight: 600;
}

@media (max-width: 680px) {
  .tagContainer {
    padding: 0px 8px;
  }
}
