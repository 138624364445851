ul,
li {
  color: var(--content-primary);
}

p,
h1,
h2,
h3,
h4 {
  margin: 0;
  color: var(--content-primary);
}

h1,
h2,
h3,
h4 {
  line-height: 1;
  font-family: Roboto Flex, Arial;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: var(--content-primary);
}

.p1 {
  font-size: 1.2em;
}

p,
li,
.p2 {
  font-size: 1em;
  line-height: 1.5;
}

.p3 {
  font-size: 0.7em;
}

h1 {
  color: --var(--primary-blue);
  font-size: 4em;
  font-stretch: 125%;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.5em;
  margin: 0;
}

h4 {
  font-size: 1em;
}

li {
  font-size: 16px;
}

.stretched {
  font-stretch: 150%;
}

.logo {
  padding: 0 4px;
  border: 2px solid var(--primary-blue);
  color: var(--primary-blue);
  font-size: 24px;
  font-family: "Jost";
  font-variant: normal;
  text-decoration: none;
  letter-spacing: 1.6px;
}

@media screen and (max-width: 680px) {
  .p1 {
    font-size: 0.9em;
  }
  p,
  li,
  .p2 {
    font-size: 0.8em;
  }

  .p3 {
    font-size: 0.6em;
  }

  h1 {
    font-size: 3em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1em;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 2em;
  }
}
